import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from "axios";
// import axios, { AxiosError, AxiosResponse } from "axios";

import { Animal } from "../../types/animal";
import { FilterValues } from "../../types/filtervalues"

import { getAnimals } from "../../hooks/AnimalHooks";

import Header from "../../main/frame/Header";
import AnimalFilterForm from './AnimalFilterForm';
import AnimalListRow from "./AnimalListRow";
import Quote from '../shared/Quote';


const defaultFilter = {
  searchColumn: ''
, searchTerm: ''
, sortColumn: ''
, sortDirection: ''
}

const localKeyFilter: string = "cpAnimalFilter";

const getFilter = () => {

  let localFilter = window.sessionStorage.getItem(localKeyFilter);

  if (localFilter) {
    return JSON.parse(localFilter); 
  }

  return defaultFilter;
}



const AnimalList = () => {

  let [filter, setFilter] = useState<FilterValues>(getFilter());

  // const { data, isLoading, isError } = useQuery<Animal[], AxiosError>({
  const { data } = useQuery<Animal[], AxiosError>({
                                                  queryKey: ["animallist", filter.searchColumn, filter.searchTerm, filter.sortColumn, filter.sortDirection]
                                                , queryFn: () => getAnimals(filter)
                                                , staleTime: 3600000
                                              });


  /* get/set filter in local storage */
  useEffect(() => {
    let localFilter = window.sessionStorage.getItem(localKeyFilter);
    if (localFilter) {
      setFilter(JSON.parse(localFilter));
    }
  }, []);

  useEffect(() => {
    const strFilter = JSON.stringify(filter);
    window.sessionStorage.setItem(localKeyFilter, strFilter);
  }, [filter]);


  /* filter onchange methods */
  const onChangeFilterText = (inputName: string) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({ ...filter, [inputName]: event.target.value });
        
      };
  };

  const onChangeFilterDDL = (inputName: string) => {
      return (event: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({ ...filter, [inputName]: event.target.value });
      };
  };

  const onChangeFilterRadio = (inputName: string) => {
      return (event: React.ChangeEvent<HTMLInputElement>) => {

        let direction = 'asc';

        // const btnsRadio = document.getElementsByName("SortDirection") as NodeListOf<HTMLInputElement>; // in this case, no need to get all radio buttons
        const radioDesc = document.getElementById("sort_dir_desc") as HTMLInputElement;
        if (radioDesc.checked === true)
          direction = 'desc';

          setFilter({ ...filter, [inputName]: direction });
      };
  };



  return (
      <div className="container main_content_box">
        <Header subtitle="Fauna"/>
        <div className="row">
            <div className="col-md-12 intro_copy">
                <p>Searchable, sortable list of Animals.</p>
                <p>All photos taken in the yard, except those of the red-headed woodpecker, which were taken at the family farm in South Dakota.</p>
                <p>* Animal information taken from the sources listed on the 'Resources' page, along with my own experience.</p>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 search_tool_col">
            <AnimalFilterForm searchColumn={filter.searchColumn} searchTerm={filter.searchTerm} sortColumn={filter.sortColumn} sortDirection={filter.sortDirection}onChangeFilterText={onChangeFilterText} onChangeFilterDDL={onChangeFilterDDL} onChangeFilterRadio={onChangeFilterRadio} />
            </div>
        </div>
        <div className="row">
          <div id="animal_list_box" className="col-md-12">
            <div className="table-responsive">
                <table id="tbl_animal_list" className="table table-bordered table-striped">
                  <thead>
                      <tr>
                          <th className="cell_center">Thumb</th>
                          <th>Common Name</th>
                          <th>Scientific Name</th>
                          <th className="cell_center">Class</th>
                          <th className="cell_center">Order</th>
                          <th>Notes</th>
                      </tr>
                  </thead>
                  <tbody>
                  {data && data.map(x => <AnimalListRow {...x} key={x.animalId}/>)}{/* {...x} = spread syntax to apply all properties */}
                  </tbody>
                </table>
            </div>
          </div>
        </div>
        <Quote/>
      </div>
    );
  };
  
  export default AnimalList;